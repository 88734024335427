import { css } from '@emotion/core';
import { state, color, fontSize, fontFamily } from '../constants/styles';

const linkState = '&:hover, &:focus, &:active';

export const CtaThemes = {
  primary: css`
    color: ${color.light};
    background: ${color.clr1};
    border-color: ${color.clr1};
    ${linkState} {
      color: ${color.light};
      background: ${color.clr1Dark};
      border-color: ${color.clr1Dark};
    }
  `,
  secondary: css`
    color: ${color.light};
    background: ${color.neutralDark};
    border-color: ${color.neutralDark};
    &::before {
      color: ${color.light};
    }
    ${linkState} {
      color: ${color.light};
      background: ${color.neutralDark};
      border-color: ${color.neutralDark};
    }
  `,
  neutral: css`
    color: ${color.neutralDark};
    background: 'transparent';
    &::before {
      color: ${color.clr1};
    }
    ${linkState} {
      color: ${color.neutralDark};
      background: 'transparent';
      border-color: ${color.clr1};
    }
  `,
};

export const CtaSizes = {
  M: css`
    min-height: 5.4rem;
    padding: 1.8rem 4rem;
    font-size: inherit;
    letter-spacing: 1px;
    font-family: ${fontFamily.secondaryMedium};
    text-transform: uppercase;
  `,
  S: css`
    min-height: 3rem;
    padding: 1rem 1.6rem;
    font-size: ${fontSize.XXS};
    text-transform: uppercase;
    font-weight: 700;
  `,
};

export const CtaBase = css`
  position: relative;
  display: inline-flex;
  text-align: center;
  text-decoration: none;

  line-height: 1;
  border-radius: 666rem;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;
  > span {
    position: relative;
    z-index: 1;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    will-change: opacity, transform;
  }
  &::before {
    content: attr(data-text);
    display: block;
    position: absolute;
    padding: inherit;
    top: 0%;
    right: 0px;
    bottom: 0px;
    left: 0px;
    color: rgb(255, 255, 255);
    z-index: 2;
    opacity: 0;
    transform: translateY(-30%);
    transition: transform 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    will-change: opacity, transform;
  }
  &:hover {
    &::before {
      transform: translateY(0%);
      opacity: 1;
    }
    span {
      display: block;
      transform: translateY(100%);
      opacity: 0;
    }
  }
`;

export const CtaDisabled = css`
  opacity: ${state.disabledOpacity};
  pointer-events: none;
`;
