import React, { SFC, ReactNode } from 'react';
import styled from '@emotion/styled';
import posed from 'react-pose';
import { route } from '../../constants/app';
import AppLink from '../base/AppLink';
import ExternalLink from '../base/ExternalLink';
import Wrapper from '../base/Wrapper';
import NavButton from '../base/NavButton';
import Cta from '../base/Cta';
import logo from '../../images/logo-alqemist.svg';
import { mqUp, mqDown, color, header, fontSize } from '../../constants/styles';

const Container = styled.header`
  position: relative;
  z-index: 1;
`;

const Grid = styled.div`
  height: ${header.heightSmall};
  ${mqUp('mainNav')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${header.heightBase};
  }
`;

const GridItemNavPose = posed.nav({
  open: { height: 'auto' },
  close: { height: 0 },
});

const GridItemNav = styled(GridItemNavPose)`
  ${mqDown('mainNav')} {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 10;
    background: ${color.light};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);
  }
  ${mqUp('mainNav')} {
    position: relative;
    display: flex;
    align-items: center;
    height: auto !important;
    perspective: 100rem;
    &:not(:first-of-type) {
      margin-left: 3rem;
    }
  }
`;

const GridItemBrand = styled.div`
  display: flex;
  align-items: center;
  ${mqDown('mainNav')} {
    z-index: 11;
    position: relative;
    height: ${header.heightSmall};
  }
`;

const Nav = styled.nav`
  display: flex;
  ${mqDown('mainNav')} {
    flex-direction: column;
    align-items: center;
    letter-spacing: 1px;
    padding: 0;
    padding-top: 10rem;
    flex-direction: column-reverse;
    > * {
      margin-top: 1rem;
    }
  }
  ${mqUp('mainNav')} {
    align-items: center;
    > * {
      margin-left: 1rem;
    }
    margin-right: 4rem;
  }
`;

const NavDropdownPose = posed.div({
  open: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transform: 'perspective(30rem) rotateX(0deg)',
    transition: { duration: 200 }
  },
  close: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    transform: 'perspective(30rem) rotateX(-25deg)',
    transition: { duration: 200 }
  },
});

const NavDropdown = styled(NavDropdownPose)`
  ${mqDown('mainNav')} {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    letter-spacing: 1px;
    padding: 0;
    padding-bottom: 4rem;
    opacity: 1 !important;
    transform: rotateX(0deg) !important;
    > * {
      margin-top: 1rem;
    }
  }
  ${mqUp('mainNav')} {
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 6px 20px -14px rgba(0, 0, 0, 0.4);
    text-align: right;
    border-radius: .4rem;
    overflow: hidden;
    transform-origin: center top;
    z-index: 1;
  }
`;

interface NavLinkProps {
  to: string;
  children: ReactNode;
  activeStyle?: object;
}

const NavLink: SFC<NavLinkProps> = ({ to, children, ...rest }) => {
  const internal = /^\/(?!\/)/.test(to);
  if (internal) {
    return (
      <AppLink to={to} activeStyle={{ opacity: 0.5 }} {...rest}>
        {children}
      </AppLink>
    );
  } else {
    return (
      <ExternalLink href={to} {...rest}>
        {children}
      </ExternalLink>
    );
  }
};

const NavLinkStyled = styled(NavLink)`
  display: block;
  padding: 1rem;
  text-decoration: none;
  font-size: ${fontSize.S};
  color: ${color.neutralDark};
  font-weight: 700;
  &:hover,
  &:focus {
    color: hsla(249, 38%, 17%, 0.75);
  }
  ${mqUp('mainNav')} {
    text-transform: uppercase;
    font-size: ${fontSize.XXS};
    letter-spacing: 1px;
  }
`;

const AppHeader = ({ navOpen, toggleNavMobile, closeNavMobile, ...rest }) => (
  <Container {...rest}>
    <Wrapper size="XL">
      <Grid>
        <GridItemBrand>
          <AppLink to={route.home}>
            <img src={logo} alt="Alqemist" />{' '}
          </AppLink>
          <NavButton navOpen={navOpen} onClick={toggleNavMobile} />
        </GridItemBrand>
        <GridItemNav pose={navOpen ? 'open' : 'close'}>
          <Nav>
            <NavLinkStyled to={route.references}>Références</NavLinkStyled>
            <NavLinkStyled to={route.expertise}>Notre expertise</NavLinkStyled>
            <Cta
              to={route.contact}
              size="S"
              theme="secondary"
            >
              Contactez-nous
            </Cta>
          </Nav>
          <NavDropdown>
            <NavLinkStyled to={route.team}>L'équipe</NavLinkStyled>
            <NavLinkStyled to={route.sideProjects}>Side projects</NavLinkStyled>
            <NavLinkStyled to="https://thenetworq.landen.co/">The Networq</NavLinkStyled>
          </NavDropdown>
        </GridItemNav>
      </Grid>
    </Wrapper>
  </Container>
);

export default AppHeader;
