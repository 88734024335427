import React, { SFC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mqUp, spacing } from '../../constants/styles';
import { WrapperSizesType } from '../../appTypes';

interface WrapperProps {
  className?: string;
  size?: WrapperSizesType;
  children: ReactNode;
}

export const sizes = {
  XL: css`
    max-width: 100%;
  `,
  L: css`
    max-width: 140rem;
  `,
  M: css`
    max-width: 100rem;
  `,
  S: css`
    max-width: 60rem;
  `,
  XS: css`
    max-width: 48rem;
  `,
};

const Container = styled.div<WrapperProps>`
  padding-left: ${spacing.M};
  padding-right: ${spacing.M};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${props => sizes[props.size]};
  ${mqUp('smartphone')} {
    padding-left: ${spacing.L};
    padding-right: ${spacing.L};
  }
`;

const Wrapper: SFC<WrapperProps> = ({ children, className, size }) => (
  <Container className={className} size={size}>
    {children}
  </Container>
);

Wrapper.defaultProps = {
  size: 'L',
};

export default Wrapper;
