import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ExternalLink from '../base/ExternalLink';
import Cta from '../base/Cta';
import Wrapper from '../base/Wrapper';
import IconLinkedin from '../base/icons/iconLinkedin';
import IconGithub from '../base/icons/iconGithub';
import {
  mqUp,
  mqDown,
  color,
  spacing,
  fontFamily,
  fontSize,
} from '../../constants/styles';
import { route } from '../../constants/app';

interface FooterProps {
  subHeader?: boolean;
}

const ContainerTop = styled.section`
  position: relative;
  z-index: 10;
  text-align: center;
  padding: ${spacing.XXXXL} 0;
`;

const ContainerBottom = styled.footer`
  padding-top: ${spacing.M};
  padding-bottom: ${spacing.M};
}
  color: ${color.light};
`;

const Grid = styled.div`
  ${mqDown('smartphone')} {
    text-align: center;
    > * + * {
      margin-top: 2rem;
    }
  }
  ${mqUp('smartphone')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * + * {
      margin-left: 2rem;
    }
  }
`;

const Items = styled.ul`
  display: flex;
  justify-content: center;
  > * + * {
    margin-left: 2rem;
  }
`;

const Item = ({ url, children, ...rest }) => (
  <li {...rest}>
    <ExternalLink
      href={url}
      css={css`
        color: ${color.light};
      `}
    >
      {children && children}
    </ExternalLink>
  </li>
);

const IconStyles = css`
  display: block;
`;

const AppFooter: SFC<FooterProps> = ({ subHeader, ...rest }) => (
  <div
    {...rest}
    css={css`
      background: linear-gradient(
        90deg,
        rgb(106, 55, 145) 0%,
        rgb(74, 48, 125) 100%
      );
    `}
  >
    {subHeader && (
      <ContainerTop>
        <Wrapper size="XL">
          <p
            css={css`
              color: ${color.clr3};
              font-family: ${fontFamily.secondaryLight};
              ${mqUp('smartphone')} {
                font-size: ${fontSize.L};
              }
            `}
          >
            Envie de travailler avec nous&nbsp;?
          </p>
          <p
            css={css`
              font-family: ${fontFamily.secondaryBold};
              text-transform: uppercase;
              color: ${color.light};
              letter-spacing: 1px;
              font-size: ${fontSize.L};
              ${mqUp('smartphone')} {
                font-size: ${fontSize.XL};
              }
              ${mqUp('tablet')} {
                font-size: ${fontSize.XXL};
              }
            `}
          >
            Parlez-nous de votre projet&nbsp;!
          </p>
          <Cta
            to={route.contact}
            css={css`
              margin-top: ${spacing.XL};
            `}
          >
            Travaillons ensemble
          </Cta>
        </Wrapper>
      </ContainerTop>
    )}
    <ContainerBottom>
      <Wrapper size="XL">
        <Grid>
          <div>
            <p>Alqemist est une marque déposée de la société E-LAM &copy;</p>
          </div>
          <Items>
            <li>
              <ExternalLink href="https://prestashare.com/profil/alqemist-nJFQfPenW/recommandations?utm_source=badge">
                <img
                  src="https://mrcrstatic.blob.core.windows.net/images/badge_light@2x.png"
                  width="116px"
                  alt="Nos clients nous recommandent"
                />
              </ExternalLink>
            </li>
            <Item url={route.linkedin}>
              <IconLinkedin css={IconStyles} />
            </Item>
            <Item url={route.github}>
              <IconGithub css={IconStyles} />
            </Item>
          </Items>
        </Grid>
      </Wrapper>
    </ContainerBottom>
  </div>
);

AppFooter.defaultProps = {
  subHeader: true,
};

export default AppFooter;
