export const route = {
  home: '/',
  notFound: '/404',
  references: '/references/',
  team: '/team/',
  contact: '/contact/',
  expertise: '/expertise/',
  sideProjects: '/side-projects/',
  linkedin: 'https://www.linkedin.com/company/alqemist/about/',
  github: 'https://github.com/Alqemist-labs',
};
