import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';

const IconLinkedin: SFC<IconInterface> = ({
  size,
  color,
  strokeWidth,
  ...rest
}) => (
  <svg width={size} height={size} {...rest}>
    <title>{'icon-linkedin'}</title>
    <path
      d="M19 0H5a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
      fill={color}
    />
  </svg>
);

IconLinkedin.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default IconLinkedin;
