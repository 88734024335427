import React, { SFC, ReactNode } from 'react';
import { Link } from 'gatsby';
import scrollToElement from 'scroll-to-element';

interface AppLinkProps {
  to: string;
  children: ReactNode;
  activeStyle?: object;
}

const scrollToLink = (e, target) => {
  if (typeof window !== undefined) {
    const targetId = target.split('#').slice(-1)[0];
    const targetElement = document.getElementById(targetId);
    if ( targetElement ) {
      e.preventDefault()
      scrollToElement(targetElement, {
        offset: -20,
        duration: 600,
      });
    }
  }
};

const AppLink: SFC<AppLinkProps> = ({ children, to, activeStyle, ...rest }) => {

  return (
    <Link to={to} activeStyle={activeStyle} onClick={(e) => scrollToLink(e, to)} {...rest}>
      {children}
    </Link>
  );
};

export default AppLink;
