import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';

const IconGithub: SFC<IconInterface> = ({
  size,
  color,
  strokeWidth,
  ...rest
}) => (
  <svg width={size} height={size} {...rest}>
    <title>{'icon-linkedin'}</title>
    <path
      d="M12.303 0C5.51 0 0 5.509 0 12.303c0 5.437 3.525 10.048 8.414 11.674.615.113.84-.267.84-.591 0-.293-.011-1.263-.017-2.29-3.422.743-4.145-1.451-4.145-1.451-.56-1.421-1.366-1.8-1.366-1.8-1.118-.763.085-.748.085-.748 1.235.086 1.886 1.268 1.886 1.268 1.097 1.88 2.88 1.337 3.58 1.021.112-.793.43-1.336.781-1.643-2.731-.31-5.604-1.367-5.604-6.081 0-1.344.48-2.44 1.265-3.302-.125-.312-.549-1.564.122-3.257 0 0 1.032-.33 3.383 1.261.981-.274 2.033-.41 3.08-.414 1.045.005 2.097.142 3.08.416 2.347-1.594 3.38-1.262 3.38-1.262.673 1.695.25 2.945.124 3.256.788.862 1.264 1.958 1.264 3.302 0 4.726-2.877 5.767-5.618 6.072.443.382.835 1.13.835 2.277 0 1.645-.017 2.97-.017 3.376 0 .328.224.71.847.59 4.886-1.63 8.408-6.24 8.408-11.674C24.607 5.51 19.097 0 12.303 0z"
      fill={color}
    />
  </svg>
);

IconGithub.defaultProps = {
  size: 24,
  color: 'currentColor',
};

export default IconGithub;
