export enum color {
  transparent = 'transparent',

  light = '#fff',

  neutralDark = 'hsl(249, 38%, 17%)',
  neutral = 'hsl(232, 19%, 56%)',
  neutralLight = 'hsla(0, 0%, 13%, 0.5)',
  neutralLighter = '#f8faff',

  clr1Dark = 'hsl(342, 73%, 53%)',
  clr1 = 'hsl(342, 89%, 63%)',
  clr1Light = 'hsl(342, 92%, 90%)',

  clr2Dark = 'hsl(249, 36%, 17%)',
  clr2 = 'hsl(249, 43%, 66%)',
  clr2Light = 'hsl(263, 45%, 89%)',

  clr3 = 'hsl(36, 91%, 73%)',
  clr3Light = 'hsl(36, 95%, 92%)',

  clr4 = 'hsl(156, 72%, 66%)',
  clr4Light = 'hsl(156, 76%, 94%)',

  clr5 = 'hsl(192, 88%, 71%)',
  clr5Light = 'hsl(192, 91%, 91%)',

  clr6 = 'hsl(274, 45%, 39%)',
  clr6Light = 'hsl(263, 45%, 89%)',

  borderInput = 'hsla(0, 0%, 13%, 0.125)',
  borderInputDark = 'hsla(0, 0%, 13%, 0.25)',
}

export enum fontFamily {
  primary = 'Open Sans, sans-serif',
  code = "Consolas, 'Roboto Mono', 'Liberation Mono', Menlo, Courier,",
  secondaryBold = 'Radikal Bold',
  secondaryMedium = 'Radikal Medium',
  secondaryLight = 'Radikal Light',
}

export enum fontWeight {
  light = 300,
  regular = 400,
  medium = 500,
  bold = 700,
}

export enum fontSize {
  XXS = '1.2rem',
  XS = '1.4rem',
  S = '1.6rem',
  M = '1.8rem',
  L = '2rem',
  XL = '2.4rem',
  XXL = '3.2rem',
  XXXL = '4.8rem',
  XXXXL = '6.4rem',
}

export enum spacing {
  XS = '.4rem',
  S = '.8rem',
  M = '1.6rem',
  L = '3.2rem',
  XL = '4.8rem',
  XXL = '6.4rem',
  XXXL = '8rem',
  XXXXL = '9.6rem',
}

export enum lineHeight {
  XS = 1,
  S = 1.15,
  M = 1.25,
  L = 1.5,
  XL = 1.625,
}
export enum radius {
  XS = '.2rem',
  S = '.4rem',
}

export enum state {
  disabledOpacity = 0.25,
}

export enum breakpoint {
  smartphone = 480,
  tablet = 768,
  desktop = 1024,
  desktopLarge = 1280,
  mainNav = 666,
}

export const mqUp = size => `@media (min-width: ${breakpoint[size] || size}px)`;
export const mqDown = size => `@media (max-width: ${(breakpoint[size] || size) - 1}px)`;

export enum header {
  heightBase = '10rem',
  heightSmall = '8rem',
}
