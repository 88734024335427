module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteLanguage: 'fr', // Language Tag on <html> element
  siteTitle: 'Alqemist - product studio', // Navigation and Site Title
  siteTitleShort: 'Alqemist', // short_name for manifest
  siteUrl: 'https://www.alqemist.com/', // Domain of your site. No trailing slash!
  siteDescription: 'Alqemist - product studio',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@_alqemist_', // Twitter Username
  // ogSiteName: 'sutterlity', // Facebook Site Name
  // ogLanguage: 'fr_FR', // Facebook Language

  // Manifest and Progress color
  themeColor: '#663399',
  backgroundColor: '#663399',
};
